import { HistoricalEventsPage, ManualEventModel } from "./../../models/thorEvents/eventModels";
import { apiRoutes } from "api/apiRoutes";
import * as eventsApi from "api/models/events/eventsApi";
import { torAxios, torAxiosIgnoreErrors } from "api/serviceConfig";
import { AxiosRequestConfig } from "axios";
import { Page } from "models/shared/Page";
import { EventListItem, EventDetails, HistoricalEventModel, Flow, DeviceData } from "models/thorEvents/eventModels";
import { AssignedUserModel, ExcursionResultModel, FullNameAttachmentModel } from "api/models/events/eventsApi";
import { AttachmentModel } from "models/shared/AttachmentModel";

export class EventsService {
    listEvents = async (request: eventsApi.ListEventsRequest) => {
        const url = apiRoutes.events.listEvents(request.customerId);

        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                direction: request.direction,
                orderBy: request.orderBy,
                dueDate: request.dueDate,
                priority: request.priority,
                eventCodes: request.eventCodes,
                evaluationStatus: request.evaluationStatus,
                assignedUsers: request.assignedUsers,
                searchValue: request.searchValue,
                searchParameter: request.searchParameter,
            },
        };

        const result = await torAxiosIgnoreErrors.get<Page<EventListItem>>(url, requestConfig);
        return result;
    };

    getEventShortInfo = async (request: eventsApi.GetEventShortInfoRequest) => {
        const url = apiRoutes.events.getEventShortInfo(request.customerId, request.eventId);
        const result = await torAxiosIgnoreErrors.get<eventsApi.GetEventShortInfoResponse>(url);
        return result;
    };

    getEventDetails = async (request: eventsApi.GetEventDetailsRequest) => {
        const url = apiRoutes.events.getEventDetails(request.customerId, request.eventId);
        const result = await torAxiosIgnoreErrors.get<EventDetails>(url);
        return result;
    };

    getEventDeviceData = async (request: eventsApi.GetEventDeviceDataRequest) => {
        const url = apiRoutes.events.getEventDeviceData(request.customerId, request.eventId);
        const result = await torAxiosIgnoreErrors.get<DeviceData[]>(url);
        return result;
    };

    makeProductDecision = async (request: eventsApi.MakeProductDecisionRequest) => {
        const url = apiRoutes.events.makeProductDecision(request.customerId, request.eventId, request.assessmentId);

        const body = {
            justification: request.justification,
            assessmentDecision: request.assessmentDecision,
            authModel: request.authModel,
        };

        const result = await torAxios.patch(url, body);
        return result;
    };

    getBatchHistory = async (request: eventsApi.GetBatchHistoryRequest) => {
        const { customerId, eventId, batchId, ...params } = request;
        const url = apiRoutes.events.getBatchHistory(customerId, eventId, batchId);
        const requestConfig = { params };

        const result = await torAxiosIgnoreErrors.get<HistoricalEventsPage<HistoricalEventModel>>(url, requestConfig);

        return result;
    };

    getRsbSummaryForBatch = async (request: eventsApi.GetRsbSummaryForBatchRequest) => {
        const url = apiRoutes.events.getRsbSummaryForBatch(request.customerId, request.eventId, request.batchId);

        const result = await torAxiosIgnoreErrors.get<eventsApi.RsbAdjustmentForRangeDetails[]>(url);
        return result;
    };

    getProductFlows = async (request: eventsApi.GetProductFlowsRequest) => {
        const url = apiRoutes.events.getProductFlows(request.customerId, request.eventId, request.productId);
        const result = await torAxiosIgnoreErrors.get<Flow[]>(url);
        return result;
    };

    setFlowForAssessment = async (request: eventsApi.SetFlowForAssessmentRequest) => {
        const url = apiRoutes.events.setFlowForAssessment(
            request.customerId,
            request.eventId,
            request.assessmentId,
            request.flowId
        );

        const result = await torAxios.post(url);
        return result;
    };

    changeDeliveryTemperatureRange = async (request: eventsApi.ChangeDeliveryTemperatureRangeRequest) => {
        const url = apiRoutes.events.changeDeliveryTemperatureRange(request.customerId, request.eventId);

        const body = {
            temperatureRangeId: request.temperatureRangeId,
        };

        const result = await torAxios.post(url, body);
        return result;
    };

    setEventTimezone = async (request: eventsApi.SetEventTimezoneRequest) => {
        const url = apiRoutes.events.setEventTimezone(request.customerId, request.eventId);

        const body = {
            timezone: request.timezone,
        };

        const result = await torAxios.patch(url, body);
        return result;
    };

    setEventPriority = async (request: eventsApi.SetEventPriorityRequest) => {
        const url = apiRoutes.events.setEventPriority(request.customerId, request.eventId);

        const body = {
            priorityId: request.priorityId,
        };

        const result = await torAxios.patch<string>(url, body);
        return result;
    };

    setEventCodes = async (request: eventsApi.SetEventCodesRequest) => {
        const url = apiRoutes.events.setEventCodes(request.customerId, request.eventId);

        const body = {
            eventCodes: request.eventCodesIds,
        };

        const result = await torAxios.patch(url, body);
        return result;
    };

    assignUserToEvent = async (request: eventsApi.AssignUserToEventRequest) => {
        const url = apiRoutes.events.assignUserToEvent(request.customerId, request.eventId);

        const body = {
            userId: request.userId,
            assignUserType: request.assignUserType,
            comment: request.comment,
            notShowEmptyContent: request.notShowEmptyContent,
        };

        const result = await torAxios.patch<AssignedUserModel>(url, body);
        return result;
    };

    completeEventReview = async (request: eventsApi.CompleteAssessmentReviewRequest) => {
        const url = apiRoutes.events.completeEventReview(request.customerId, request.eventId);

        const body = {
            isWithCustomer: request.isWithCustomer,
        };

        const result = await torAxios.patch(url, body);
        return result;
    };

    downloadFromS3WithPresignedUrl = async (request: eventsApi.DownloadFromS3Request) => {
        // Authorization: "" to skip interceptor with authorization to avoid aws error;
        const requestConfig: AxiosRequestConfig = {
            responseType: "blob",
            headers: {
                Authorization: "",
            },
        };

        const result = await torAxios.get<Blob>(request.url, requestConfig);
        return result;
    };

    confirmFileUpload = async (request: eventsApi.ConfirmFileUploadRequest) => {
        const url = apiRoutes.events.confirmFileUpload(request.customerId, request.eventId, request.attachmentId);
        const result = await torAxios.post(url);
        return result;
    };

    getDownloadPresignedUrl = async (request: eventsApi.GetDownloadPresignedUrlRequest) => {
        const url = apiRoutes.events.getDownloadPresignedUrl(request.customerId, request.eventId, request.attachmentId);
        const result = await torAxios.get<eventsApi.GetDownloadPresignedUrlResponse>(url);
        return result;
    };

    getDownloadUrlFromS3 = async (request: eventsApi.GetDownloadUrlFromS3Request) => {
        const url = apiRoutes.events.getDownloadPresignedS3Url(request.customerId, request.entityId);

        const requestConfig: AxiosRequestConfig = {
            params: {
                bucketFileName: request.bucketFileName,
            },
        };

        const result = await torAxios.get<string>(url, requestConfig);
        return result;
    };

    deleteEventAttachment = async (request: eventsApi.DeleteEventAttachmentRequest) => {
        const url = apiRoutes.events.deleteEventAttachment(request.customerId, request.eventId, request.attachmentId);
        const result = torAxios.delete(url);
        return result;
    };

    getEventAttachments = async (request: eventsApi.GetEventAttachmentsRequest) => {
        const url = apiRoutes.events.getEventAttachments(request.customerId, request.eventId);
        const config: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
            },
        };

        const result = await torAxiosIgnoreErrors.get<Page<eventsApi.EventAttachmentWithFullNameResponse>>(url, config);
        return result;
    };

    setEventType = async (request: eventsApi.SetEventTypeRequest) => {
        const url = apiRoutes.events.setEventType(request.customerId, request.eventId);

        const body = {
            eventType: request.eventType,
        };

        const result = await torAxios.patch(url, body);
        return result;
    };

    setDueDate = async (request: eventsApi.SetDueDateRequest) => {
        const url = apiRoutes.events.setDueDate(request.customerId, request.eventId);

        const body = {
            dueDate: request.date,
        };

        const result = await torAxios.patch(url, body);
        return result;
    };

    setQuarantineDate = async (request: eventsApi.SetQuarantineDateRequest) => {
        const url = apiRoutes.events.setQuarantineDate(request.customerId, request.eventId);

        const body = {
            quarantineDate: request.date,
        };

        const result = await torAxios.patch(url, body);
        return result;
    };

    resetAllEvents = async (request: eventsApi.ResetAllEventsRequest) => {
        const url = apiRoutes.events.resetAllEvents(request.customerId);
        const result = await torAxios.post(url);
        return result;
    };

    resetEvent = async (request: eventsApi.ResetEventRequest) => {
        const url = apiRoutes.events.resetEvent(request.customerId, request.eventId);
        const result = await torAxios.post(url);
        return result;
    };

    setShippingDate = async (request: eventsApi.SetShippingDateRequest) => {
        const url = apiRoutes.events.setShippingDate(request.customerId, request.eventId);

        const body = {
            shippingDate: request.date,
        };

        const result = await torAxios.patch(url, body);
        return result;
    };

    setDeliveryDate = async (request: eventsApi.SetDeliveryDateRequest) => {
        const url = apiRoutes.events.setDeliveryDate(request.customerId, request.eventId);

        const body = {
            deliveryDate: request.date,
        };

        const result = await torAxios.patch(url, body);
        return result;
    };

    conditionEvent = async (request: eventsApi.ConditionEventRequest) => {
        const url = apiRoutes.events.conditionEvent(request.customerId, request.eventId);

        const body = {
            authModel: request.authModel,
            conditioningStartDate: request.conditioningStartDate,
            conditioningEndDate: request.conditioningEndDate,
            conditioningNote: request.conditioningNote,
        };

        const result = await torAxios.post(url, body);
        return result;
    };

    calculateDevicesExcursion = async (request: eventsApi.CalculateDevicesExcursionRequest) => {
        const { customerId, ...body } = request;
        const url = apiRoutes.events.calculateDevicesExcursion(customerId);
        return await torAxiosIgnoreErrors.post<ExcursionResultModel>(url, body);
    };

    calculateManualExcursion = async (request: eventsApi.CalculateManualExcursionRequest) => {
        const { customerId, ...body } = request;
        const url = apiRoutes.events.calculateManualExcursion(customerId);
        return await torAxiosIgnoreErrors.post<eventsApi.ExcursionResultModel>(url, body);
    };

    createManualEvent = async (request: eventsApi.CreateManualEventRequest) => {
        const url = apiRoutes.events.createManualEvent(request.customerId);

        const body = {
            ...request.model,
            justification: request.justification,
            authModel: request.authModel,
        };

        const result = await torAxios.post(url, body);
        return result;
    };

    getManualEventInfo = async (request: eventsApi.GetEventDetailsRequest) => {
        const url = apiRoutes.events.getModelForEventEditing(request.customerId, request.eventId);
        const result = await torAxiosIgnoreErrors.get<ManualEventModel>(url);
        return result;
    };

    editManualEvent = async (request: eventsApi.EditManualEventRequest) => {
        const url = apiRoutes.events.editEvent(request.customerId, request.eventId);

        const body = {
            ...request.model,
            justification: request.justification,
            authModel: request.authModel,
        };

        const result = await torAxios.post(url, body);
        return result;
    };

    generatePdf = async (request: eventsApi.GetPdfSummaryRequest) => {
        const url = apiRoutes.events.generatePdf(request.customerId, request.eventId);

        const requestConfig: AxiosRequestConfig = {
            params: { currentTimezone: request.currentTimezone, eventTimezone: request.eventTimezone },
            responseType: "blob",
        };

        const result = await torAxios.get(url, requestConfig);

        return result;
    };

    getlistEventsWithDuplicateDevices = async (request: eventsApi.getEventsWithDevicesRequest) => {
        const url = apiRoutes.events.getDuplicateDeviceEvents(request.customerId);
        const body = {
            deviceIds: request.deviceIds,
            eventId: request.eventId,
        };

        const result = await torAxiosIgnoreErrors.post<eventsApi.DuplicatedDeviceEventsResponse[]>(url, body);
        return result;
    };

    getBatchDistributionDiagram = async (request: eventsApi.GetBatchDistributionDiagramRequest) => {
        const url = apiRoutes.events.getBatchDistributionDiagram(
            request.customerId,
            request.eventId,
            request.assessmentId
        );

        const result = await torAxiosIgnoreErrors.get<eventsApi.GetBatchDistributionDiagramResponse>(url);
        return result;
    };

    uploadFileAndSaveToDb = async (request: eventsApi.UploadFileRequest) => {
        const url = apiRoutes.events.uploadFileAndSaveToDb(request.customerId, request.eventId);

        const formData = new FormData();
        formData.append("file", request.file);

        const result = await torAxiosIgnoreErrors.post<AttachmentModel>(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return result;
    };

    uploadFile = async (request: eventsApi.UploadFileRequest) => {
        const url = apiRoutes.events.uploadFile(request.customerId);

        const formData = new FormData();
        formData.append("file", request.file);

        const result = await torAxiosIgnoreErrors.post<FullNameAttachmentModel>(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return result;
    };
}
