import { CustomerLevelRequest } from "../sharedModels/CustomerLevelRequest";

export enum ReportType {
    EventRootCauseAnalysis = 0,
    EventDurationToClose = 1,
    EventFinalProductDecisions = 2,
    EventOverdue = 3,
}

export interface GetReportCsvRequest extends CustomerLevelRequest {
    type: ReportType;
    currentTimezone: string;
}
