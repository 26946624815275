import React, { Fragment } from "react";
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import { routes } from "routes";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import { formatTemperatureRange } from "helpers/formatTemperatureRange";
import { formatDurationToString } from "helpers/formatDurationToString";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { dateToFormat } from "helpers/dateFormattingHelper";
import { commonConstants } from "lynxConstants";
import { batchDistributionDiagramPageStyles, greyedOutClass } from "./BatchDistributionDiagramPageStyles";
import { EventType, HistoricalEventModel } from "models/thorEvents/eventModels";
import clsx from "clsx";
import { LynxIcon } from "icons/LynxIcon";
import { LynxRecommendationIcon } from "components/LynxRecommendationIcon";
import { getAssessmentDecisionText, getAssessmentRecommendationText } from "helpers/getRecommendationText";

interface EventCardProps {
    event: HistoricalEventModel;
    isHighlighted: boolean;
}

export const EventCard = ({ event, isHighlighted }: EventCardProps) => {
    const eventTableHeaders = ["Temperature Range", "Excursion", ...(event.isCurrent ? ["RSB"] : [])];

    const classes = batchDistributionDiagramPageStyles();

    const isAssessmentNotEvaluatedYet = event.decision == null;
    const decision = event.isCurrent ? event.decision ?? event.recommendedDecision : event.decision;

    return (
        <div
            key={event.eventId}
            className={clsx(classes.eventCard, {
                [classes.eventAssessmentNotEvaluated]: isAssessmentNotEvaluatedYet,
                [greyedOutClass]: isHighlighted && !event.isRelevant,
            })}
        >
            {event.isCurrent && <div className={classes.selectedEventTriangle} />}
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Grid container>
                        <Grid>
                            {isAssessmentNotEvaluatedYet && (
                                <LynxIcon name="triangleWarning" className={classes.alertTriangle} />
                            )}
                            <LynxRedirectLink
                                className={clsx(classes.eventLink, {
                                    [classes.notEvaluatedEventLink]: isAssessmentNotEvaluatedYet,
                                })}
                                color={isAssessmentNotEvaluatedYet ? "critical600" : "blue500"}
                                variant="body-sbold-s"
                                typographyComponent={"span"}
                                target="_blank"
                                to={routes.eventDetails.to(event.eventId)}
                            >
                                Event {event.eventDisplayId}
                            </LynxRedirectLink>
                            <Span variant="body-xs" className={classes.eventType}>
                                {EventType[event.eventType]}
                            </Span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <LynxTypography variant="body-xs" color="neutral400" className={classes.eventCreatedDate}>
                        {dateToFormat(event.eventCreatedAt, commonConstants.shortDateFormat, true)}
                    </LynxTypography>
                </Grid>
            </Grid>
            <Table>
                <TableHead>
                    <TableRow>
                        {eventTableHeaders.map((header) => (
                            <TableCell key={header} className={classes.eventTableCell}>
                                <LynxTypography variant="body-xs" color="neutral400">
                                    {header}
                                </LynxTypography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {event.impacts
                        .sort((a, b) => a.position - b.position)
                        .map((range) => (
                            <TableRow key={range.impactId}>
                                <TableCell className={classes.eventTableCell}>
                                    <LynxTypography variant={range.excursionDuration > 0 ? "body-sbold-s" : "body-xs"}>
                                        {formatTemperatureRange(
                                            range.rangeDisplayLowerLimit,
                                            range.rangeDisplayUpperLimit,
                                            range.rangeDisplayLowerLimitOperator,
                                            range.rangeDisplayUpperLimitOperator
                                        )}
                                    </LynxTypography>
                                </TableCell>
                                <TableCell className={classes.eventTableCell}>
                                    <LynxTypography variant={range.excursionDuration > 0 ? "body-sbold-s" : "body-xs"}>
                                        {formatDurationToString(range.excursionDuration)}
                                    </LynxTypography>
                                </TableCell>
                                {event.isCurrent && (
                                    <TableCell className={classes.eventTableCell}>
                                        <LynxTypography
                                            variant={range.excursionDuration > 0 ? "body-sbold-s" : "body-xs"}
                                        >
                                            {formatDurationToString(range.productRsb)}
                                        </LynxTypography>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <div className={classes.assessment}>
                {decision !== null && <LynxRecommendationIcon recomendation={decision} />}
                <div>
                    <LynxTypography
                        variant={decision === null ? "body-xs" : "body-sbold-s"}
                        color={decision === null ? "neutral400" : "neutral500"}
                    >
                        {getAssessmentDecisionText(decision, true)}
                    </LynxTypography>
                    {event.isCurrent &&
                        getAssessmentRecommendationText(event.recommendationMessages).map((message, i) => [
                            <Fragment key={`${i}-break`}>{i > 0 && <br />}</Fragment>,
                            <LynxTypography key={i} variant="body-s" color="neutral600">
                                {message}
                            </LynxTypography>,
                        ])}
                </div>
            </div>
        </div>
    );
};
