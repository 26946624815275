import { Box, Grid } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import { Form, Formik, FormikHelpers } from "formik";
import { convertDateToSelectedTimezone, dateInLocalTimezone } from "helpers/timezoneHelper";
import { LynxIcon } from "icons/LynxIcon";
import { commonConstants } from "lynxConstants";
import { observer } from "mobx-react";
import { CommentFormModal, CommentModel, CommentType } from "models/commonModels";
import { useEffect, useState } from "react";
import { useStore } from "store/StoreConfigs";
import SetApiValidationErrors from "validation/HelperComponents/SetApiValidationsErrors";
import { commentsValidationSchema } from "validation/ValidationSchemas/commentsValidationSchema";
import InfoSubheader from "../InfoSubheader";
import { LynxAvatarWithTooltip } from "../LynxAvatar/LynxAvatarWithTooltip";
import { lynxCommentsStyles } from "./LynxCommentsStyles";

interface LynxCommentsProps {
    rootEntityId?: string;
    timezone?: string;
    commentsSectionId?: string;
    notShowEmptyStateIfNoComments?: boolean;
    rootEntityEditModeEnabled?: boolean;
}

const LynxComments = observer((props: LynxCommentsProps) => {
    const {
        rootEntityId,
        timezone,
        commentsSectionId = "lynx-comments",
        notShowEmptyStateIfNoComments = false,
        rootEntityEditModeEnabled = false,
    } = { ...props };
    const { identityStore, commonStore } = useStore();
    const [pageNumber, setPageNumber] = useState(1);

    const classes = lynxCommentsStyles();
    const pageSize = 10;
    const commentInitialValue: CommentFormModal = { comment: "" };

    const inputComponent = (
        <LynxInputForm name={`comment`} multiline minRows={3} placeholder="Leave your comment here..." />
    );

    const displayCommentType = (comment: CommentModel) => {
        switch (comment.createdSection) {
            case CommentType.TemperatureGraph:
                return "provided annotation of temperature graph";
            case CommentType.Assessment:
                return "provided decision for assessment";
            case CommentType.EditEventBody:
                return "provided handling unit information";
            case CommentType.CreateEvent:
                return "created event";
            case CommentType.EditEvent:
                return "edited event";
            case CommentType.AssignEvent:
                return (
                    <LynxTypography variant="body-s" color="neutral400" component={"span"}>
                        assigned the event to&nbsp;
                        <LynxTypography variant="body-medium" color="neutral600" component={"span"}>
                            {comment.payload?.firstName ?? ""} {comment.payload?.lastName ?? ""}
                        </LynxTypography>
                    </LynxTypography>
                );
            default:
                break;
        }
    };

    const showLoadCommentsCount = () => {
        const unloadedCount = commonStore.commentsTotalCount - commonStore.comments.length;

        return unloadedCount > pageSize ? pageSize : unloadedCount;
    };

    const handleClick = () => {
        setPageNumber(pageNumber + 1);
    };

    const handleSubmit = async (values: CommentFormModal, formikHelpers: FormikHelpers<CommentFormModal>) => {
        if (rootEntityId) {
            await commonStore.createComment({
                comment: values.comment,
                customerId: identityStore.currentCustomer.id,
                entityId: rootEntityId,
            });
        }

        // after successful comment creation input form is cleared and page number is reset to 1 to reload first set of comments
        if (!commonStore.commentErrors) {
            formikHelpers.resetForm();
            setPageNumber(1);
        }
    };

    // TODO: Multiple getComments request due to mounting LynxComments component in a EventDetails
    useEffect(() => {
        if (rootEntityId) {
            commonStore.getComments(
                {
                    customerId: identityStore.currentCustomer.id,
                    entityId: rootEntityId,
                    pageNumber: 1,
                    pageSize: pageSize,
                },
                true
            );
        }
    }, [commonStore.commentFirstSetLoadingCount]);

    useEffect(() => {
        if (pageNumber <= 1) {
            return;
        }

        if (rootEntityId) {
            commonStore.getComments(
                {
                    customerId: identityStore.currentCustomer.id,
                    entityId: rootEntityId,
                    pageNumber: pageNumber,
                    pageSize: pageSize,
                },
                false
            );
        }
    }, [pageNumber]);

    useEffect(() => {
        return () => {
            commonStore.resetCommentsData();
        };
    }, []);

    return (
        <>
            <Grid item className={classes.gridMarginBotom}>
                <InfoSubheader
                    text={`comments (${commonStore.commentsTotalCount})`}
                    icon={<LynxIcon name="comments" />}
                />
            </Grid>
            {commonStore.comments.length === 0 && !notShowEmptyStateIfNoComments && (
                <LynxTypography variant="body-s" color="neutral300" className={classes.emptyStateMargin}>
                    No comments here yet
                </LynxTypography>
            )}

            <Grid item container wrap="nowrap" className={classes.gridMarginBotom}>
                <Grid item className={classes.avatarContainer}>
                    <LynxAvatarWithTooltip
                        email={identityStore.currentUser.id}
                        firstName={identityStore.currentUser.firstName}
                        lastName={identityStore.currentUser.lastName}
                    />
                </Grid>
                <Grid item xs>
                    {rootEntityEditModeEnabled ? (
                        inputComponent
                    ) : (
                        <Formik
                            initialValues={commentInitialValue}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validationSchema={commentsValidationSchema()}
                            onSubmit={handleSubmit}
                        >
                            <Form id={commentsSectionId}>
                                {inputComponent}
                                <LynxButton
                                    form={commentsSectionId}
                                    type="submit"
                                    variant="secondary"
                                    className={classes.buttonWithIcon}
                                    disabled={commonStore.progressFlags.loadingComments}
                                    loading={commonStore.progressFlags.loadingComments}
                                    leftIcon={<LynxIcon name="plusSmall" />}
                                >
                                    Add Comment
                                </LynxButton>

                                <SetApiValidationErrors storeErrors={commonStore.commentErrors} />
                            </Form>
                        </Formik>
                    )}
                </Grid>
            </Grid>

            <Grid container className={clsx(commonStore.progressFlags.loadingComments && classes.disabledContainer)}>
                {commonStore.comments.map((comment) => (
                    <Grid key={comment.id} item container wrap="nowrap" className={classes.gridCommentBottom}>
                        <Grid item className={classes.avatarContainer}>
                            {comment.userEmail !== commonConstants.system && (
                                <LynxAvatarWithTooltip
                                    email={comment.userEmail}
                                    firstName={comment.userFirstName ?? ""}
                                    lastName={comment.userLastName ?? ""}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            className={clsx({
                                [classes.noAvatarMargin]: comment.userEmail === commonConstants.system,
                            })}
                        >
                            <Box display="flex" gridGap="0.2rem" marginBottom="0.3rem" alignItems="baseline">
                                <LynxTypography variant="body-medium">
                                    {comment.userEmail === commonConstants.system
                                        ? commonConstants.systemPascalCase
                                        : `${comment.userFirstName} ${comment.userLastName}`}
                                    &nbsp;
                                    <LynxTypography color="neutral400" component={"span"} variant="body-s">
                                        {displayCommentType(comment)}
                                    </LynxTypography>
                                </LynxTypography>
                            </Box>
                            <LynxTypography variant="body-s" color="neutral300" className={classes.dateTimeMargin}>
                                {(timezone
                                    ? convertDateToSelectedTimezone(comment.createdAt, timezone)
                                    : dateInLocalTimezone(comment.createdAt)
                                ).format(commonConstants.fullDateTimeFormat)}
                            </LynxTypography>
                            {comment.comment ? (
                                <LynxTypography className={classes.contentStyles}>{comment.comment}</LynxTypography>
                            ) : comment.payload?.notShowEmptyStateIfNoContent ? null : (
                                <LynxTypography variant="body-s" color="neutral300" className={classes.contentStyles}>
                                    No comments
                                </LynxTypography>
                            )}
                        </Grid>
                    </Grid>
                ))}

                {commonStore.commentsTotalCount > commonStore.comments.length && (
                    <Grid item>
                        <LynxButton
                            variant="tertiary"
                            onClick={handleClick}
                            disabled={commonStore.progressFlags.loadingNextSetOfComments}
                            loading={commonStore.progressFlags.loadingNextSetOfComments}
                            leftIcon={<LynxIcon name="angleSmallDown" />}
                        >
                            Show {showLoadCommentsCount()} More
                        </LynxButton>
                    </Grid>
                )}
            </Grid>
        </>
    );
});

export default LynxComments;
