import {
    HistoricalDeliveryModel,
    GetBatchDistributionDiagramRequest,
    GetBatchDistributionDiagramResponse,
} from "api/models/events/eventsApi";
import { services } from "api/serviceConfig";
import { commonConstants } from "lynxConstants";
import { makeAutoObservable } from "mobx";
import CommonStore from "./CommonStore";

interface ProgressFlags {
    loadingBatchDistributionDiagram: boolean;
}

interface GroupedDeliveriesDetails {
    originPhysicalId?: string;
    originName?: string;
    destinationPhysicalId?: string;
    destinationName?: string;
    deliveries: HistoricalDeliveryModel[];
}

export default class BatchDistributionDiagramStore {
    constructor(commonStore: CommonStore) {
        makeAutoObservable(this);
        this.commonStore = commonStore;
    }

    commonStore: CommonStore;

    progressFlags: ProgressFlags = {
        loadingBatchDistributionDiagram: false,
    };

    areRelevantObjectsHighlighted = true;

    groupedDeliveriesDetails: GroupedDeliveriesDetails | null = null;

    get blankDiagramData(): GetBatchDistributionDiagramResponse {
        return {
            eventId: "",
            eventDisplayId: 0,
            eventTimezone: commonConstants.UTCTimezone,
            productFullName: "",
            batchNumber: "",
            stabilityFormId: null,
            historyShownFrom: "",
            historyShownTo: "",
            assessmentClosedAt: null,
            locations: [],
            deliveries: [],
            flowName: "",
            impacts: [],
        };
    }

    diagramData = this.blankDiagramData;

    setAreRelevantObjectsHighlighted = (value: boolean) => {
        this.areRelevantObjectsHighlighted = value;
    };

    setProgressFlags = (value: Partial<ProgressFlags>) => {
        this.progressFlags = { ...this.progressFlags, ...value };
    };

    setBatchDistributionDiagram = (value: GetBatchDistributionDiagramResponse) => {
        this.diagramData = value;
    };

    resetBatchDistributionDiagram = () => {
        this.diagramData = this.blankDiagramData;
    };

    openGroupedDeliveriesDetails = (
        originId: string | null,
        destinationId: string | null,
        deliveryNumbers: string[]
    ) => {
        const origin = this.diagramData.locations.find((x) => x.physicalId === originId);
        const destination = this.diagramData.locations.find((x) => x.physicalId === destinationId);

        const deliveries = this.diagramData.deliveries.filter((x) => deliveryNumbers.includes(x.deliveryNumber));
        this.groupedDeliveriesDetails = {
            originPhysicalId: origin?.physicalId,
            originName: origin?.name,
            destinationPhysicalId: destination?.physicalId,
            destinationName: destination?.name,
            deliveries,
        };
    };

    clearGroupedDeliveriesDetails = () => {
        this.groupedDeliveriesDetails = null;
    };

    getBatchDistributionDiagram = async (request: GetBatchDistributionDiagramRequest) => {
        this.setProgressFlags({ loadingBatchDistributionDiagram: true });

        try {
            const response = await services.Events.getBatchDistributionDiagram(request);

            if (response.status === 200) {
                response.data.impacts.sort((a, b) => a.position - b.position);
                this.setBatchDistributionDiagram(response.data);
                return this.diagramData;
            } else {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return null;
            }
        } finally {
            this.setProgressFlags({ loadingBatchDistributionDiagram: false });
        }
    };
}
