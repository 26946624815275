import { NodeProps } from "@xyflow/react";
import { batchDistributionDiagramPageStyles, getGreyedOutClass } from "./BatchDistributionDiagramPageStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxUnknownLocationNode } from "./BatchDistributionDiagramTypes";
import { DummyNodeHandle } from "./DummyNodeHandle";
import clsx from "clsx";

export const UnknownLocationNode = (props: NodeProps<LynxUnknownLocationNode>) => {
    const classes = batchDistributionDiagramPageStyles();

    return (
        <>
            <div className={clsx(classes.locationNode, getGreyedOutClass(props.data))}>
                <LynxTypography variant="body-sbold-s" color="white">
                    Unknown
                </LynxTypography>
            </div>
            <DummyNodeHandle type="source" />
            <DummyNodeHandle type="target" />
        </>
    );
};
