import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { ListStabilityFormsRequest } from "api/models/stabilityForms/stabilityFormsApi";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { FiltersComponent } from "components/ReusableComponents/FiltersComponent/FiltersComponent";
import FiltersResultEmptyState from "components/ReusableComponents/FiltersComponent/FiltersResultEmptyState";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { LynxAvatarWithTooltip } from "components/ReusableComponents/LynxAvatar/LynxAvatarWithTooltip";
import ThorPagination from "components/ReusableComponents/ThorPagination/ThorPagination";
import { commonConstants } from "lynxConstants";
import { dateToFormat } from "helpers/dateFormattingHelper";
import { parseJsonForRequest } from "helpers/jsonParser";
import { getPermissionKey } from "helpers/permissionHelpers";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import { stabilityFormsStyles } from "./StabilityFormStyles";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";
import { noFilterResultMessage } from "lynxConstants/filterSearchMessages";
import { PaginationArea } from "models/shared/Page";
import { HeaderPortal } from "components/ReusableComponents/HeaderPortal";

export const StabilityFormsView = observer(() => {
    const { stabilityFormStore, identityStore, userStore, permissionsStore, commonStore } = useStore();
    const navigate = useNavigate();
    const classes = stabilityFormsStyles();

    const [stickyEnabled, setStickyEnabled] = useState(false);
    const withSticky = (className: string) => clsx(className, stickyEnabled && "sticky");

    const tableHeaders: { title: string; width: number }[] = [
        { title: "Product Name", width: 20 },
        { title: "Dose Form/Dosage", width: 20 },
        { title: "Form Number", width: 20 },
        { title: "Effective Date", width: 20 },
        { title: "Form Owner", width: 20 },
    ];

    const loadForms = () => {
        const request: ListStabilityFormsRequest = {
            customerId: identityStore.currentCustomer.id,
            pageNumber: stabilityFormStore.currentPage,
            pageSize: stabilityFormStore.pageSize,
            formOwners: parseJsonForRequest(stabilityFormStore.formOwnerSelectedFilters),
            searchValue: stabilityFormStore.searchInputTrimmed,
            searchParameter: stabilityFormStore.searchParameter,
        };

        stabilityFormStore.loadStabilityForms(request);
    };

    const permissionKey = getPermissionKey(actions.customer.tor.stabilityForms.view, identityStore.currentCustomer.id);
    const permissionStatus = permissionsStore.getPermissionStatus(permissionKey);

    useEffect(() => {
        if (identityStore.isSystemSpace || permissionStatus === MarvelPermissionStatus.Deny) {
            navigate(identityStore.startPageAvailable);
        }
    }, [identityStore.isSystemSpace, identityStore.startPageAvailable]);

    const viewDetailsPermissionKey = getPermissionKey(
        actions.customer.tor.stabilityForms.viewDetails,
        identityStore.currentCustomer.id
    );

    const modifyPermissionKey = getPermissionKey(
        actions.customer.tor.stabilityForms.modify,
        identityStore.currentCustomer.id
    );

    useEffect(() => {
        if (permissionStatus !== MarvelPermissionStatus.Allow) {
            return;
        }

        setStickyEnabled(false);
        loadForms();

        return () => commonStore.setShowGeneralErrorPageToFalse();
    }, [permissionStatus, stabilityFormStore.currentPage, stabilityFormStore.pageSize]);

    if (permissionStatus !== MarvelPermissionStatus.Allow && !commonStore.showGeneralErrorPage) {
        return <FullScreenLoadingIndicator />;
    }

    const title = `Stability Data Management (${stabilityFormStore.totalCount})`;

    const renderHeader = () => (
        <TableHead>
            <TableRow className={classes.headerRow}>
                {tableHeaders.map((header) => (
                    <TableCell
                        className={clsx(classes.cellBorderBottom, classes.headerCell)}
                        key={header.title}
                        width={`${header.width}%`}
                    >
                        <LynxTypography color="neutral400" variant="h3">
                            {header.title}
                        </LynxTypography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );

    return (
        <GeneralErrorComponentWrapper>
            <main className={classes.root}>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <FiltersComponent
                    title={title}
                    filters={[
                        {
                            options: userStore.customerUsers.map((x) => ({
                                itemDisplayName: `${x.firstName} ${x.lastName}`,
                                itemValue: x.id,
                            })),
                            selectedOptions: stabilityFormStore.formOwnerSelectedFilters,
                            label: "Form Owner",
                            changeFunction: stabilityFormStore.setFormOwnerFilter,
                            isUsersDropdown: true,
                            isOptionsDownloaded: userStore.progressFlags.loadingUsersForDropdown,
                        },
                    ]}
                    request={loadForms}
                    resetFilters={stabilityFormStore.resetAllFilters}
                    search={{
                        searchValue: stabilityFormStore.searchValue,
                        setSearchValue: stabilityFormStore.setSearchValue,
                        searchParameterProps: {
                            type: "product",
                            parameter: stabilityFormStore.searchParameter,
                            setSearchParameter: stabilityFormStore.setSearchParameter,
                        },
                    }}
                    buttonComponent={
                        <LynxButton
                            size="medium"
                            onClick={() => navigate(routes.stabilityFormsCreate)}
                            disabled={!permissionsStore.hasPermission(modifyPermissionKey)}
                            loading={permissionsStore.permissionLoading(modifyPermissionKey)}
                        >
                            Add Stability Form
                        </LynxButton>
                    }
                />

                {stabilityFormStore.progressFlags.loadingStabilityForms ? (
                    <div>
                        <FullScreenLoadingIndicator />
                    </div>
                ) : (
                    <div className={classes.tableContainer}>
                        {stabilityFormStore.stabilityForms.length === 0 ? (
                            <FiltersResultEmptyState message={noFilterResultMessage} />
                        ) : (
                            <>
                                <HeaderPortal
                                    callback={setStickyEnabled}
                                    stickyEnabled={stickyEnabled}
                                    firstLevel={false}
                                >
                                    <Table className={withSticky(classes.stickyHeader)}>
                                        {stickyEnabled && renderHeader()}
                                    </Table>
                                </HeaderPortal>

                                <Table>
                                    {!stickyEnabled && renderHeader()}
                                    <TableBody>
                                        {stabilityFormStore.stabilityForms.map((stabilityForm) => (
                                            <React.Fragment key={stabilityForm.id}>
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell className={classes.cellBorderBottom} width={"20%"}>
                                                        <LynxTypography
                                                            variant="body-l"
                                                            className={classes.fieldMaxWidth}
                                                        >
                                                            <LynxRedirectLink
                                                                to={routes.stabilityFormDetails.to(stabilityForm.id)}
                                                                disabled={
                                                                    !permissionsStore.hasPermission(
                                                                        viewDetailsPermissionKey
                                                                    )
                                                                }
                                                                loading={permissionsStore.permissionLoading(
                                                                    viewDetailsPermissionKey
                                                                )}
                                                            >
                                                                {stabilityForm.productName}
                                                            </LynxRedirectLink>
                                                        </LynxTypography>
                                                    </TableCell>
                                                    <TableCell className={classes.cellBorderBottom} width={"20%"}>
                                                        <LynxTypography
                                                            variant="body-l"
                                                            className={classes.fieldMaxWidth}
                                                        >
                                                            {stabilityForm.productRepresentations
                                                                .map(
                                                                    (x) =>
                                                                        `${x.doseForm} (${
                                                                            x.allDosagesFlag
                                                                                ? "All"
                                                                                : `${x.dosage} ${x.unitOfMeasure}`
                                                                        })`
                                                                )
                                                                .join(", ")}
                                                        </LynxTypography>
                                                    </TableCell>
                                                    <TableCell className={classes.cellBorderBottom} width={"20%"}>
                                                        <LynxTypography
                                                            variant="body-l"
                                                            className={classes.fieldMaxWidth}
                                                        >
                                                            {stabilityForm.number ?? commonConstants.emptyValue}
                                                        </LynxTypography>
                                                    </TableCell>
                                                    <TableCell className={classes.cellBorderBottom} width={"20%"}>
                                                        <LynxTypography variant="body-l">
                                                            {dateToFormat(
                                                                stabilityForm.effectiveDate,
                                                                commonConstants.shortDateFormat,
                                                                true
                                                            )}
                                                        </LynxTypography>
                                                    </TableCell>
                                                    <TableCell className={classes.cellBorderBottom} width={"20%"}>
                                                        <LynxTypography variant="body-l">
                                                            {stabilityForm.ownerEmail ? (
                                                                <LynxAvatarWithTooltip
                                                                    email={stabilityForm.ownerEmail}
                                                                    firstName={stabilityForm.ownerFirstName}
                                                                    lastName={stabilityForm.ownerLastName}
                                                                />
                                                            ) : (
                                                                commonConstants.emptyValue
                                                            )}
                                                        </LynxTypography>
                                                    </TableCell>
                                                </TableRow>
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                                {stabilityFormStore.stabilityForms.length > 0 && (
                                    <ThorPagination
                                        page={stabilityFormStore.currentPage}
                                        pages={stabilityFormStore.totalPages}
                                        onPageChange={stabilityFormStore.moveToPage}
                                        isLastPage={stabilityFormStore.isLastPage}
                                        setPage={stabilityFormStore.setPage}
                                        area={PaginationArea.StabilityForms}
                                        setPageSize={stabilityFormStore.setPageSize}
                                    />
                                )}
                            </>
                        )}
                    </div>
                )}
            </main>
        </GeneralErrorComponentWrapper>
    );
});
