import { Position, InternalNode, Node, MarkerType } from "@xyflow/react";
import { HistoricalDeliveryModel, GetBatchDistributionDiagramResponse } from "api/models/events/eventsApi";
import _ from "lodash";
import ELK, { ElkExtendedEdge, ElkNode } from "elkjs/lib/elk.bundled.js";
import { Point } from "chart.js";
import {
    EdgeParams,
    LynxLocationNode,
    LynxUnknownLocationNode,
    LynxDeliveryNode,
    LynxNode,
    LynxEdge,
    LynxDeliveryEdge,
    EdgeDirection,
    LynxGroupedDeliveriesNode,
} from "./BatchDistributionDiagramTypes";
import { theme } from "styling/theme";
import { dateToFormat } from "helpers/dateFormattingHelper";
import { commonConstants } from "lynxConstants";
import { HistoricalEventModel } from "models/thorEvents/eventModels";

const deliveryGroupingThresholdTotal = 6;
const deliveryGroupingThresholdInOneDirection = 3;

const defaultDeliveryEdgeStyles = {
    markerEnd: {
        type: MarkerType.ArrowClosed,
        color: theme.colors.primary.blue200,
    },
    style: {
        stroke: theme.colors.primary.blue200,
        strokeWidth: 2,
    },
} as const;

const greyedOutDeliveryEdgeStyles = {
    markerEnd: {
        type: MarkerType.ArrowClosed,
        color: theme.colors.neutrals.neutral200,
    },
    style: {
        stroke: theme.colors.neutrals.neutral200,
        strokeWidth: 2,
    },
} as const;

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
const getNodeIntersection = (intersectionNode: InternalNode<Node>, targetNode: InternalNode<Node>): Point => {
    // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
    const intersectionNodePosition = intersectionNode.internals.positionAbsolute;
    const targetPosition = targetNode.internals.positionAbsolute;

    const w = (intersectionNode.measured.width || 1) / 2;
    const h = (intersectionNode.measured.height || 1) / 2;

    const x2 = intersectionNodePosition.x + w;
    const y2 = intersectionNodePosition.y + h;
    const x1 = targetPosition.x + (targetNode.measured.width || 1) / 2;
    const y1 = targetPosition.y + (targetNode.measured.height || 1) / 2;

    const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
    const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
    const a = 1 / (Math.abs(xx1) + Math.abs(yy1) || 1);
    const xx3 = a * xx1;
    const yy3 = a * yy1;
    const x = w * (xx3 + yy3) + x2;
    const y = h * (-xx3 + yy3) + y2;

    return { x, y };
};

// returns the position (top, right, bottom or right) of passed node compared to the intersection point
const getEdgePosition = (node: InternalNode<Node>, intersectionPoint: Point): Position => {
    // @ts-ignore positionAbsolute exists on measured but not defined in typescript declaration file
    const n = { ...node.measured.positionAbsolute, ...node };
    const nx = Math.round(n.x);
    const ny = Math.round(n.y);
    const px = Math.round(intersectionPoint.x);
    const py = Math.round(intersectionPoint.y);

    if (px <= nx + 1) {
        return Position.Left;
    }
    if (px >= nx + n.measured.width - 1) {
        return Position.Right;
    }
    if (py <= ny + 1) {
        return Position.Top;
    }
    if (py >= n.y + n.measured.height - 1) {
        return Position.Bottom;
    }

    return Position.Top;
};

export const getEdgeParams = (source: InternalNode<Node>, target: InternalNode<Node>): EdgeParams => {
    const sourceIntersectionPoint = getNodeIntersection(source, target);
    const targetIntersectionPoint = getNodeIntersection(target, source);

    const sourcePosition = getEdgePosition(source, sourceIntersectionPoint);
    const targetPosition = getEdgePosition(target, targetIntersectionPoint);

    return {
        sourceX: sourceIntersectionPoint.x,
        sourceY: sourceIntersectionPoint.y,
        targetX: targetIntersectionPoint.x,
        targetY: targetIntersectionPoint.y,
        sourcePosition,
        targetPosition,
    };
};

const getLocationNodeId = (physicalId: string) => `location_${physicalId}`;
const getDeliveryNodeId = (delivery: HistoricalDeliveryModel) => {
    return `delivery_${delivery.uniqueIdForDeliveryMatch}`;
};

const getDeliveryDestinationNodeId = (x: HistoricalDeliveryModel) => {
    if (x.destinationPhysicalId) {
        return getLocationNodeId(x.destinationPhysicalId);
    } else if (x.originPhysicalId) {
        return `unknownDestinationForOrigin_${x.originPhysicalId}`;
    } else {
        return "unknownDestinationForUnknownOrigin";
    }
};

const getDeliveryOriginNodeId = (x: HistoricalDeliveryModel) => {
    if (x.originPhysicalId) {
        return getLocationNodeId(x.originPhysicalId);
    } else if (x.destinationPhysicalId) {
        return `unknownOriginForDestination_${x.destinationPhysicalId}`;
    } else {
        return "unknownOriginForUnknownDestination";
    }
};

const getGroupedDeliveriesNodeId = (source: string, target: string) => {
    return `groupedDeliveries_${source}_${target}`;
};

const getAdditionalSpaceForCurrentNode = (node: LynxNode) => {
    return (node.data.events as HistoricalEventModel[])?.some((x) => x.isCurrent) ? 25 : 0;
};

const getEdgeId = (deliveryNodeId: string, source: string, target: string) =>
    `edge_${deliveryNodeId}_${source}_${target}`;

export const getDeliveryEdgeStyles = (areRelevantObjectsHighlighted: boolean, isOnRelevantPath: boolean) => {
    return areRelevantObjectsHighlighted && !isOnRelevantPath ? greyedOutDeliveryEdgeStyles : defaultDeliveryEdgeStyles;
};

const getGroupedDeliveriesNode = (
    source: string,
    target: string,
    deliveryNodes: LynxDeliveryNode[]
): LynxGroupedDeliveriesNode => {
    return {
        id: getGroupedDeliveriesNodeId(source, target),
        data: {
            deliveryNumbers: deliveryNodes.map((x) => x.data.deliveryNumber).sort(),
            sourceNodeId: source,
            targetNodeId: target,
            originPhysicalId: deliveryNodes[0].data.originPhysicalId,
            destinationPhysicalId: deliveryNodes[0].data.destinationPhysicalId,
            isHighlighted: deliveryNodes[0].data.isHighlighted,
            isOnRelevantPath: deliveryNodes[0].data.isOnRelevantPath,
        },
        position: { x: 0, y: 0 },
        type: "groupedDeliveriesNode",
    };
};

export const getInitialNodesForDiagram = (
    diagram: GetBatchDistributionDiagramResponse,
    areRelevantObjectsHighlighted: boolean
): LynxNode[] => {
    const locationNodes: LynxLocationNode[] = diagram.locations.map((x) => ({
        id: getLocationNodeId(x.physicalId),
        data: {
            code: x.code,
            name: x.name,
            events: x.events,
            isHighlighted: areRelevantObjectsHighlighted,
            isOnRelevantPath: x.isOnRelevantPath,
        },
        position: { x: 0, y: 0 },
        type: "locationNode",
    }));

    const unknownOriginNodes: LynxUnknownLocationNode[] = diagram.deliveries
        .filter((x) => !x.originPhysicalId)
        .map((x) => {
            const isOnRelevantPath =
                diagram.locations.some((y) => y.isOnRelevantPath && y.physicalId === x.destinationPhysicalId) ||
                x.events.some((y) => y.isCurrent);

            return {
                id: getDeliveryOriginNodeId(x),
                data: {
                    isHighlighted: areRelevantObjectsHighlighted,
                    isOnRelevantPath,
                },
                position: { x: 0, y: 0 },
                type: "unknownLocationNode",
            };
        });

    const unknownDestinationNodes: LynxUnknownLocationNode[] = diagram.deliveries
        .filter((x) => !x.destinationPhysicalId)
        .map((x) => ({
            id: getDeliveryDestinationNodeId(x),
            data: {
                isHighlighted: areRelevantObjectsHighlighted,
                isOnRelevantPath: x.events.some((y) => y.isCurrent),
            },
            position: { x: 0, y: 0 },
            type: "unknownLocationNode",
        }));

    let deliveryNodes: LynxDeliveryNode[] = diagram.deliveries.map((x) => ({
        id: getDeliveryNodeId(x),
        data: {
            deliveryNumber: x.deliveryNumber,
            shippingDate: x.shippingDate,
            deliveryDate: x.deliveryDate,
            originPhysicalId: x.originPhysicalId,
            destinationPhysicalId: x.destinationPhysicalId,
            sourceNodeId: getDeliveryOriginNodeId(x),
            targetNodeId: getDeliveryDestinationNodeId(x),
            events: x.events,
            isHighlighted: areRelevantObjectsHighlighted,
            isOnRelevantPath: x.isOnRelevantPath,
        },
        position: { x: 0, y: 0 },
        type: "deliveryNode",
    }));

    const groupedDeliveriesNodes: LynxGroupedDeliveriesNode[] = [];

    const groupedDeliveries = _.groupBy(
        deliveryNodes.filter((y) => y.data.events.length === 0),
        (x) => {
            return [x.data.sourceNodeId, x.data.targetNodeId].sort().join("-");
        }
    );

    _.forOwn(groupedDeliveries, (deliveries, key) => {
        if (deliveries.length >= deliveryGroupingThresholdTotal) {
            const uniqueNodes = _.sortedUniq(deliveries.map((x) => [x.data.sourceNodeId, x.data.targetNodeId]).flat());

            const nodeA = uniqueNodes[0];
            const nodeB = uniqueNodes.length > 1 ? uniqueNodes[1] : nodeA;

            if (nodeA === nodeB) {
                groupedDeliveriesNodes.push(getGroupedDeliveriesNode(nodeA, nodeB, deliveries));
                deliveryNodes = deliveryNodes.filter((x) => !deliveries.includes(x));
            } else {
                const deliveriesFromA = deliveries.filter((x) => x.data.sourceNodeId === nodeA);
                const deliveriesFromB = deliveries.filter((x) => x.data.sourceNodeId === nodeB);

                if (deliveriesFromA.length >= deliveryGroupingThresholdInOneDirection) {
                    groupedDeliveriesNodes.push(getGroupedDeliveriesNode(nodeA, nodeB, deliveriesFromA));
                    deliveryNodes = deliveryNodes.filter((x) => !deliveriesFromA.includes(x));
                }

                if (deliveriesFromB.length >= deliveryGroupingThresholdInOneDirection) {
                    groupedDeliveriesNodes.push(getGroupedDeliveriesNode(nodeB, nodeA, deliveriesFromB));
                    deliveryNodes = deliveryNodes.filter((x) => !deliveriesFromB.includes(x));
                }
            }
        }
    });

    const result = _.sortedUniqBy(
        [
            ...locationNodes,
            ...unknownOriginNodes,
            ...unknownDestinationNodes,
            ...deliveryNodes,
            ...groupedDeliveriesNodes,
        ],
        (x) => x.id
    );

    // increase z-index for current node so the triangle is not covered by other nodes
    result.forEach((x) => {
        x.zIndex = (x.data.events as HistoricalEventModel[])?.some((y) => y.isCurrent) ? 1001 : undefined;
    });

    return result;
};

const getElkEdges = (nodes: LynxNode[]) => {
    const elkEdges: ElkExtendedEdge[] = [];

    nodes.forEach((x) => {
        if (x.type === "deliveryNode" || x.type === "groupedDeliveriesNode") {
            if (x.data.sourceNodeId === x.data.targetNodeId) {
                elkEdges.push({
                    id: getEdgeId(x.id, x.data.sourceNodeId, x.id),
                    sources: [x.data.sourceNodeId],
                    targets: [x.id],
                });

                elkEdges.push({
                    id: getEdgeId(x.id, x.id, x.data.sourceNodeId),
                    sources: [x.id],
                    targets: [x.data.sourceNodeId],
                });
            } else {
                elkEdges.push({
                    id: getEdgeId(x.id, x.data.sourceNodeId, x.data.targetNodeId),
                    sources: [x.data.sourceNodeId],
                    targets: [x.data.targetNodeId],
                    labels: [
                        {
                            layoutOptions: {
                                "elk.edgeLabels.inline": "true",
                            },
                            // some text is required
                            text: "label",
                            width: (x.measured?.width ?? 0) + getAdditionalSpaceForCurrentNode(x),
                            height: x.measured?.height,
                        },
                    ],
                });
            }
        }
    });

    return elkEdges;
};

const transformReactFlowNodesToElkNodes = (nodes: LynxNode[]): ElkNode[] => {
    const elkNodes: ElkNode[] = nodes
        .filter(
            (x) =>
                ((x.type === "deliveryNode" || x.type === "groupedDeliveriesNode") &&
                    x.data.sourceNodeId === x.data.targetNodeId) ||
                x.type === "locationNode" ||
                x.type === "unknownLocationNode"
        )
        .map((x) => ({
            id: x.id,
            width: (x.measured?.width ?? 0) + getAdditionalSpaceForCurrentNode(x),
            height: x.measured?.height,
        }));

    return elkNodes;
};

const copyReactFlowNode = (node: LynxNode, x: number, y: number): LynxNode => {
    return {
        id: node.id,
        type: node.type,
        data: node.data,
        position: { x, y },
        zIndex: node.zIndex,
    } as LynxNode;
};

const createReactFlowEdge = (
    deliveryNode: LynxDeliveryNode | LynxGroupedDeliveriesNode,
    direction: EdgeDirection,
    circular: boolean = false
): LynxDeliveryEdge => {
    const source = direction === "incoming" ? deliveryNode.data.sourceNodeId : deliveryNode.id;
    const target = direction === "incoming" ? deliveryNode.id : deliveryNode.data.targetNodeId;

    return {
        id: getEdgeId(deliveryNode.id, source, target),
        source,
        target,
        data: {
            direction,
            circular,
            isHighlighted: deliveryNode.data.isHighlighted,
            isOnRelevantPath: deliveryNode.data.isOnRelevantPath,
        },
        ...getDeliveryEdgeStyles(deliveryNode.data.isHighlighted, deliveryNode.data.isOnRelevantPath),
        type: "deliveryEdge",
    };
};

const getElkNodePosition = (layoutResult: ElkNode, nodeId: string): Point => {
    const elkNode = layoutResult.children?.find((y) => y.id === nodeId);

    if (elkNode?.x === undefined || elkNode?.y === undefined) {
        throw new Error("Node position cannot be undefined");
    }

    return { x: elkNode.x, y: elkNode.y };
};

const getElkEdgeLabelPosition = (layoutResult: ElkNode, node: LynxDeliveryNode | LynxGroupedDeliveriesNode): Point => {
    const edgeId = getEdgeId(node.id, node.data.sourceNodeId, node.data.targetNodeId);
    const elkEdge = layoutResult.edges?.find((y) => y.id === edgeId);
    if (elkEdge?.labels?.[0]?.x === undefined || elkEdge?.labels?.[0]?.y === undefined) {
        throw new Error("Edge label position cannot be undefined");
    }

    return { x: elkEdge.labels[0].x, y: elkEdge.labels[0].y };
};

// for layout with elk we treat deliveries not as separate nodes but as edge labels, this results in better layout
// for circular deliveries where origin = destination we're making an exception and rendering them as nodes for better layout
// after layout is performed, we take positions of elk nodes and edge labels and assign those positions to reactflow nodes
// for reactflow we treat deliveries as nodes to allow dragging
// when computing layout, elkjs routes edges around nodes and other edges to reduce overlapping and improve visibility,
// this results in each edge having an optional array of path sections, stored in ElkExtendedEdge.sections property
// for now we're ignoring these sections and render edges as straight paths to allow node dragging
export const layoutNodes = async (nodes: LynxNode[]) => {
    const elkNodes = transformReactFlowNodesToElkNodes(nodes);
    const elkEdges = getElkEdges(nodes);

    const elkGraph: ElkNode = {
        id: "root",
        // https://eclipse.dev/elk/reference/options.html
        layoutOptions: {
            "elk.algorithm": "layered",
            "elk.direction": "DOWN",
            "elk.edgeRouting": "POLYLINE",
        },
        children: elkNodes,
        edges: elkEdges,
    };

    // It is possible to compute layout in a web worker thread by passing { workerUrl: "..." } into ELK() constructor
    // but create-react-app doesn't load web-worker library properly so we're doing it on the main thread
    // for more details see https://github.com/kieler/elkjs
    const layoutResult = await new ELK().layout(elkGraph, { logging: true });

    // we are creating a completely new list of node objects instead of updating positions of old ones, this is required for ReactFlow
    const nodesAfterLayout: LynxNode[] = [];
    const edgesAfterLayout: LynxEdge[] = [];

    nodes.forEach((node) => {
        let position: Point = { x: 0, y: 0 };

        if (node.type === "unknownLocationNode" || node.type === "locationNode") {
            position = getElkNodePosition(layoutResult, node.id);
        } else if (node.type === "deliveryNode" || node.type === "groupedDeliveriesNode") {
            const circular = node.data.sourceNodeId === node.data.targetNodeId;

            position = circular
                ? getElkNodePosition(layoutResult, node.id)
                : getElkEdgeLabelPosition(layoutResult, node);

            edgesAfterLayout.push(createReactFlowEdge(node, "incoming", circular));
            edgesAfterLayout.push(createReactFlowEdge(node, "outgoing", circular));
        }

        position.x += getAdditionalSpaceForCurrentNode(node);

        nodesAfterLayout.push(copyReactFlowNode(node, position.x, position.y));
    });

    return { nodesAfterLayout, edgesAfterLayout };
};

export const diagramFormatDate = (date?: string | null) => {
    return date ? dateToFormat(date, commonConstants.shortDateFormat, true) : commonConstants.notAvailable;
};
