import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const eventsStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.listPagePadding,
        },

        titleContainer: { marginBottom: "20px" },
        title: {
            lineHeight: "41px",
            fontSize: "41px",
            fontWeight: 500,
            color: theme.colors.neutrals.neutral600,
            marginBottom: "5px",
        },
        subTitle: {
            color: theme.colors.neutrals.neutral400,
            width: "350px",
        },
        subText: {
            marginRight: "5px",
            fontWeight: 400,
        },
        new: {
            color: "rgba(179, 0, 33, 1)",
            fontWeight: 500,
            marginTop: "5px",
        },
        iconSpacing: {
            marginRight: "8px",
        },
        iconContainer: {
            display: "flex",
        },

        cellBorderBottom: {
            borderBottom: "none",
        },
        headerCell: {
            paddingBottom: "1rem",
        },
        tableContainer: {
            marginTop: "3rem",
        },
        table: {
            borderSpacing: "0 0.5rem",
            borderCollapse: "inherit",
            tableLayout: "fixed",
            width: "100%",
            "&.sticky": {
                borderCollapse: "collapse",
                margin: "-0.5rem 0 -1rem 0",
            },
        },
        headerRow: {
            verticalAlign: "bottom",
        },
        tableRow: {
            boxShadow: theme.mainShadow,
            backgroundColor: "white",
            verticalAlign: "top",
        },

        detailsDropdown: {
            backgroundColor: theme.colors.primary.blue75,
            padding: "1rem 4rem",
        },

        displayNone: {
            "&.sticky": {
                display: "none",
            },
        },

        sortIcon: {
            width: "1rem",
            height: "1rem",
            marginTop: "0.13rem",
            cursor: "pointer",
            "& path": {
                fill: "currentColor",
            },
            marginLeft: "0.3rem",
        },
        expandableToggleText: {
            cursor: "pointer",
            color: theme.colors.primary.blue500,
            width: "8rem",
            textAlign: "right",
        },
        rotatedSortIcon: {
            rotate: "180deg",
        },
        detailsSectionIcon: {
            verticalAlign: "middle",
            marginLeft: "0.25rem",
        },

        sortingHeader: {
            cursor: "pointer",

            "&:hover": {
                textDecoration: "underline",
                textUnderlineOffset: "0.2rem",
                color: theme.colors.neutrals.neutral500,
            },
        },

        selectedSortingHeader: {
            color: theme.colors.primary.blue300,

            "&:hover": {
                color: theme.colors.primary.blue300,
            },
        },

        detailsSectionHeadersMargin: {
            marginBottom: "0.5rem",
        },
        paddingNone: {
            padding: "0rem",
        },
        paddingBottomNone: {
            paddingBottom: "0rem",
        },
        paddingTopNone: {
            paddingTop: "0rem",
        },

        detailsSectionTable: {
            "& td": {
                paddingTop: "0.3rem",
                paddingBottom: "0.3rem",
            },
            "&:first-child > td": {
                paddingTop: "0rem",
                paddingBottom: "0.3rem",
            },
            "&:last-child > td": {
                border: "none",
            },
        },
        fieldMaxWidth: {
            maxWidth: "30rem",
            wordWrap: "break-word",
        },

        wordBreak: {
            wordBreak: "break-word",
        },
    })
);
