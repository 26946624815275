import "@xyflow/react/dist/style.css";
import { observer } from "mobx-react";
import { Controls, ReactFlow, useEdgesState, useNodesInitialized, useNodesState, useReactFlow } from "@xyflow/react";
import { useEffect, useState } from "react";
import { LocationNode } from "./LocationNode";
import { UnknownLocationNode } from "./UnknownLocationNode";
import { DeliveryNode } from "./DeliveryNode";
import { DeliveryEdge } from "./DeliveryEdge";
import { batchDistributionDiagramPageStyles } from "./BatchDistributionDiagramPageStyles";
import { getDeliveryEdgeStyles, getInitialNodesForDiagram, layoutNodes } from "./batchDistributionDiagramHelpers";
import { useStore } from "store/StoreConfigs";
import { LynxEdge, LynxEdgeType, LynxNode, LynxNodeType } from "./BatchDistributionDiagramTypes";
import { CenteredLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/CenteredLoadingIndicator";
import { GroupedDeliveriesNode } from "./GroupedDeliveriesNode";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { formatTemperatureRange } from "helpers/formatTemperatureRange";
import { formatDurationToString } from "helpers/formatDurationToString";

const nodeTypes: { [key in LynxNodeType]: any } = {
    deliveryNode: DeliveryNode,
    locationNode: LocationNode,
    unknownLocationNode: UnknownLocationNode,
    groupedDeliveriesNode: GroupedDeliveriesNode,
};

const edgeTypes: { [key in LynxEdgeType]: any } = {
    deliveryEdge: DeliveryEdge,
};

export const BatchDistributionDiagram = observer(() => {
    const classes = batchDistributionDiagramPageStyles();
    const { batchDistributionDiagramStore } = useStore();
    const initialNodes = getInitialNodesForDiagram(
        batchDistributionDiagramStore.diagramData,
        batchDistributionDiagramStore.areRelevantObjectsHighlighted
    );

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState<LynxEdge>([]);

    const [layoutComputed, setLayoutComputed] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const nodesInitialized = useNodesInitialized();
    const { fitView } = useReactFlow();

    useEffect(() => {
        const updatedNodes = nodes.map((node) => ({
            ...node,
            data: {
                ...node.data,
                isHighlighted: batchDistributionDiagramStore.areRelevantObjectsHighlighted,
            },
        }));

        const updatedEdges = edges.map((edge) => {
            return {
                ...edge,
                data: {
                    ...edge.data,
                    isHighlighted: batchDistributionDiagramStore.areRelevantObjectsHighlighted,
                },
                ...getDeliveryEdgeStyles(
                    batchDistributionDiagramStore.areRelevantObjectsHighlighted,
                    !!edge.data?.isOnRelevantPath
                ),
            };
        });

        setNodes(updatedNodes as LynxNode[]);
        setEdges(updatedEdges as LynxEdge[]);
    }, [batchDistributionDiagramStore.areRelevantObjectsHighlighted]);

    useEffect(() => {
        (async () => {
            if (nodesInitialized && !layoutComputed) {
                const result = await layoutNodes(nodes);
                setNodes(result.nodesAfterLayout);
                setEdges(result.edgesAfterLayout);
                setLayoutComputed(true);
            }
        })();
    }, [setNodes, setEdges, fitView, nodesInitialized, layoutComputed]);

    useEffect(() => {
        if (nodesInitialized && layoutComputed) {
            fitView();
            setShowLoading(false);
        }
    }, [nodesInitialized, layoutComputed]);

    return (
        <div className={classes.diagramContainer}>
            <ReactFlow
                maxZoom={10}
                minZoom={0.1}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                proOptions={{ hideAttribution: true }}
            >
                <Controls
                    showInteractive={false}
                    showFitView={true}
                    position="bottom-right"
                    className={classes.diagramControls}
                />
                <Controls
                    showFitView={false}
                    showInteractive={false}
                    showZoom={false}
                    position="top-right"
                    className={classes.stabilityTableContainer}
                >
                    <LynxTypography variant="body-sbold-s" color="neutral400">
                        Flow: {batchDistributionDiagramStore.diagramData.flowName}
                    </LynxTypography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.stabilityTableHeaderCell}>
                                    <LynxTypography variant="body-xs" color="neutral400">
                                        Temperature Range
                                    </LynxTypography>
                                </TableCell>
                                <TableCell className={classes.stabilityTableHeaderCell}>
                                    <LynxTypography variant="body-xs" color="neutral400">
                                        Stability Budget
                                    </LynxTypography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {batchDistributionDiagramStore.diagramData.impacts.map((x) => (
                                <TableRow key={x.id}>
                                    <TableCell className={classes.stabilityTableBodyCell}>
                                        <LynxTypography>
                                            {formatTemperatureRange(
                                                x.rangeDisplayLowerLimit,
                                                x.rangeDisplayUpperLimit,
                                                x.rangeDisplayLowerLimitOperator,
                                                x.rangeDisplayUpperLimitOperator
                                            )}
                                        </LynxTypography>
                                    </TableCell>
                                    <TableCell className={classes.stabilityTableBodyCell}>
                                        <LynxTypography>
                                            {formatDurationToString(x.baseline, x.baseline !== null)}
                                        </LynxTypography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Controls>
            </ReactFlow>

            {showLoading && nodes.length > 0 && (
                <div className={classes.loadingIndicatorContainer}>
                    <CenteredLoadingIndicator />
                </div>
            )}
        </div>
    );
});
